<mat-toolbar color="primary" class="shadow position-sticky sticky-top">
  <button mat-icon-button>
    <img src="assets/logo.svg" alt="logo.svg" class="logo">
  </button>
  <span>Phonot</span>
  <span style="flex: 1 1 auto;"></span>
  <button mat-icon-button aria-label="Download">
    <mat-icon>download</mat-icon>
  </button>
</mat-toolbar>
