<div class="container p-0 m-0 box" style="background-color: #f6f6f6">
    <p>patient works!</p>
    <ul>
        <table mat-table [dataSource]="patients" class="mat-elevation-z8">
            <ng-container [matColumnDef]="column" *ngFor="let column of displayedColumns">
                <th mat-header-cell *matHeaderCellDef>{{ column }}</th>
                <td mat-cell *matCellDef="let patient">
                    Name :: {{ patient.name[0].family }} {{ patient.name[0].given[0] }}
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <button mat-flat-button color="primary" (click)="open(patient.id)">BST</button>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        </table>
    </ul>

    <ul>
        <table mat-table [dataSource]="bsts" class="mat-elevation-z8">
            <ng-container [matColumnDef]="bstColumns[0]">
                <th mat-header-cell *matHeaderCellDef> {{bstColumns[0]}} </th>
                <td mat-cell *matCellDef="let bst">
                    {{bst.valueInteger}}
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </td>
            </ng-container>

            <ng-container [matColumnDef]="bstColumns[1]">
                <th mat-header-cell *matHeaderCellDef> {{bstColumns[1]}} </th>
                <td mat-cell *matCellDef="let bst">
                    {{bst.valueTime | date:'dd/MM/yyyy'}}
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="bstColumns"></tr>
            <tr mat-row *matRowDef="let emprow; columns: bstColumns"></tr>
        </table>
    </ul>

</div>