import { Component, OnInit } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';

@Component({
  selector: 'app-provider',
  templateUrl: './provider.component.html',
  styleUrls: ['./provider.component.scss']
})
export class ProviderComponent implements OnInit {

  constructor(private readonly httpClient: HttpClient) {
  }

  get<T>(url: string): Observable<T> {
    return this.httpClient.get<T>(url);
  }

  ngOnInit(): void {
  }

  onSubmit(event: any): void {
    console.log(event.target.patientID.value);
    this.httpClient.get<string>('test_patient/' + event.target.patientID.value).subscribe( arg => {
      console.log(arg);
    });
  }
}
