<div class="container m-0 w-100 p-4 box" style="background-color: #333333">
  <div class="row">
    <div class="col-8">
      <p class="w-100">
    <span>
    Copyright © Phonot. All rights reserved.
    </span>
      </p>
    </div>
    <div class="col-4">
      <p>
        주식회사 포넛
      </p>
      <p>
        사업자 등록번호 : 264-81-52023
      </p>
    </div>
  </div>
</div>
