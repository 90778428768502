import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { IndexComponent } from './subDirs/index/index.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import {MaterialModule} from './engine/modules/material.module';
import { NavComponent } from './subDirs/nav/nav.component';
import {RouterModule} from '@angular/router';
import { FooterComponent } from './subDirs/footer/footer.component';
import {AppRoute} from './app-route';
import { ProviderComponent } from './subDirs/fhir/provider/provider.component';
import { PatientComponent } from './subDirs/fhir/patient/patient.component';
import {HttpClientModule} from '@angular/common/http';
import { BstComponent } from './subDirs/fhir/bst/bst.component';

@NgModule({
  declarations: [
    AppComponent,
    IndexComponent,
    NavComponent,
    FooterComponent,
    ProviderComponent,
    PatientComponent,
    BstComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    NgbModule,
    MaterialModule,
    ServiceWorkerModule.register('ngsw-worker.js', {enabled: environment.production}),
    RouterModule,
    AppRoute,
    HttpClientModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
