import { Component, OnInit } from '@angular/core';
import {List, ListEntry, ObservationComponent, Patient} from 'fhir/r4';
import {HttpClient} from '@angular/common/http';
import {FhirService} from '../../../fhir/service/fhir-service.service';
import {MatTableModule} from '@angular/material/table';

// import Patient from { fhir4 };;

@Component({
  selector: 'app-patient',
  templateUrl: './patient.component.html',
  styleUrls: ['./patient.component.scss']
})
export class PatientComponent implements OnInit {
  patients: Array<Patient>;
  bsts: Array<ObservationComponent>;
  displayedColumns: string[] = [
      'Patient',
  ];
  bstColumns: string[] = [
    'BST',
    'timestamp'
  ];

  constructor(private http: HttpClient, private readonly fhirservice: FhirService) {
    fhirservice.get<{ Hello: string }>('/fhir/patient').subscribe(value => {
      console.log(value);
      this.patients = JSON.parse(JSON.stringify(value));
      console.log(this.patients);
    });
  }

  public open(patientid): void {
    this.fhirservice.get('/fhir/bst/' + patientid).subscribe( arg => {
      console.log(arg);
      this.bsts = JSON.parse(JSON.stringify(arg));
    });
  }

  ngOnInit(): void {
  }
}
