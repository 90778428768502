import { NgModule } from '@angular/core';
import {ExtraOptions, RouterModule, Routes} from '@angular/router';
import {IndexComponent} from './subDirs/index/index.component';
import {PatientComponent} from './subDirs/fhir/patient/patient.component';
import {ProviderComponent} from './subDirs/fhir/provider/provider.component';
import {BstComponent} from './subDirs/fhir/bst/bst.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: '/home',
    pathMatch: 'full'
  },
  {
    path: 'home',
    component: IndexComponent
  },
  {
    path: 'cs6440angular',
    children: [
      { path: 'patient', component: PatientComponent},
      { path: 'bst', component: BstComponent}
    ]
  },
];


const routerOptions: ExtraOptions = {
  onSameUrlNavigation: 'reload'
};


@NgModule({
  imports: [RouterModule.forRoot(routes, routerOptions)],
  exports: [RouterModule]
})
export class AppRoute { }
