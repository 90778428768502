<div class="container p-0 m-0 box" style="background-color: #f6f6f6">
  <ngb-carousel class="vw-100" #carousel [interval]="5000" [pauseOnHover]="pauseOnHover" [pauseOnFocus]="pauseOnFocus"
                (slide)="onSlide($event)" id="carousel-header">
    <ng-template ngbSlide *ngFor="let img of images; index as i">
      <div class="img-wrapper">
        <img class="w-100" [src]="img" alt="img">
      </div>

      <div class="carousel-caption" *ngIf="i === 0">
        <p class="description user-select-none text-justify" style="position: absolute; left: 60%; top: -25%">
          한걸음 더 가까이,<br><br><br><br><b>PHONOT</b>
        </p>
      </div>

      <div class="carousel-caption" *ngIf="i === 1">
        <p class="description user-select-none text-justify" style="position: absolute; left: 15%; top: -25%">
          단둘이,<br><br>누구도 모르게<br><br><br><br><b>PHONOT</b>
        </p>
      </div>

      <div class="carousel-caption" *ngIf="i === 2">
        <img src="assets/images/logo_solid_01.svg" style="width: 10%; position: absolute; left: 0%; top: -45%;">
        <br><br>
        <img src="assets/images/logo_solid_02.svg" style="width: 10%; position: absolute; left: 0%; top: -15%;">
        <br><br>
        <img src="assets/images/logo_solid_03.svg" style="width: 10%; position: absolute; left: 0%; top: 15%;">
        <br><br>
        <img src="assets/images/logo_solid_04.svg" style="width: 10%; position: absolute; left: 0%; top: 45%;">
        <p class="description user-select-none text-justify" style="position: absolute; left: 60%; top: -30%;">
          더이상 답장<br><br>기다리지 마세요<br><br><br><br><b>PHONOT</b>
        </p>
      </div>

    </ng-template>
  </ngb-carousel>

  <div class="mt-4 mb-4">
    <img src="assets/images/Explain%201.png" alt="alt" style="width: 100%">
    <div style="margin-top:-50%;padding:2%; position: absolute; left: 50%">
      <h1 class="text-justify text-black-50">
        내가 보낸 메세지, 읽지 않았을까봐 발동동 구를 필요 없어요
      </h1>
      <br>
      <h1 class="text-justify text-black-50">
        갑작스런 전화, 목소리 가다듬을 필요 없어요
      </h1>
      <br>
      <h1 class="text-justify text-black-50">
        주변이 시끄럽다구요? 상관없어요
      </h1>
    </div>
  </div>

  <div class="mt-4">
    <img src="assets/images/Explain2.png" alt="alt" style="width: 100%">
    <div style="margin-top:-50%;padding:2%; position: absolute; left: 0">
      <h2 class="text-justify text-black-50 w-50">
        조만간 앱스토어와 플레이스토어에서 만나요
      </h2>
      <br>
      <div>
        <a href="">
          <img src="assets/images/Download_on_the_App_Store_Badge_US-UK_RGB_blk_092917.svg" alt="appstore" class="w-25 mr-2">
        </a>
        <a href="">
          <img src="assets/images/google-play-badge.png" alt="appstore" class="w-25 ml-2">
        </a>
      </div>
    </div>

  </div>
</div>
